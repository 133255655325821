import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Services from '../pages/services/Services';
import SingleService from '../pages/services/SingleService';
import Login from '../pages/onboard/Login';
import NotFoundScreen from '../components/others/NotFoundScreen';
import PasswordReset from '../pages/onboard/PasswordReset';
//import Pricing from '../pages/Pricing';
import ScrollToTop from '../components/common/ScrollToTop';
import HomeSoftApplication from '../themes/index5/HomeSoftApplication';
import About from '../pages/About';
import Contact from '../pages/Contact';
import ComingSoon from '../pages/ComingSoon';
import Integrations from '../pages/integration/Integrations';
import IntegrationSingle from '../pages/integration/IntegrationSingle';
import Blogs from '../pages/blog/Blogs';
import SingleBlog from '../pages/blog/SingleBlog';
import Career from '../pages/career/Career';
import CareerSingle from '../pages/career/CareerSingle';
import HelpCenter from '../pages/help-center/HelpCenter';
import SingleHelpCenter from '../pages/help-center/SingleHelpCenter';
import Signup from '../pages/onboard/Signup';
import RequestDemo from '../pages/RequestDemo';
import SingleProduct from '../pages/product/SingleProduct';
import SinglePortfolio from '../pages/SinglePortfolio';

function index() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeSoftApplication />} />
        <Route exact path="/single-product" element={<SingleProduct />} />
        <Route exact path="/portfolio-single" element={<SinglePortfolio />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/single-service" element={<SingleService />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blog-single" element={<SingleBlog />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/career-single" element={<CareerSingle />} />
        <Route exact path="/help-center" element={<HelpCenter />} />
        <Route
          exact
          path="/help-center-single"
          element={<SingleHelpCenter />}
        />
        <Route exact path="/password-reset" element={<PasswordReset />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/request-for-demo" element={<RequestDemo />} />
        <Route exact path="/coming-soon" element={<ComingSoon />} />
        <Route exact path="/integrations" element={<Integrations />} />
        <Route
          exact
          path="/integration-single"
          element={<IntegrationSingle />}
        />
        <Route element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default index;
